'use client';

import { StaticErrorContent } from '@packages/modules/src/StaticErrorContent/StaticErrorContent';
import { useIntl } from 'react-intl';
import { PageMetaData } from '@packages/modules/src/PageMetaData/PageMetaData';

// not found file for unknown localized routes. it is rendered inner the [lang] segment layout
// TODO: needs to be updated with appropiate content
const RootNotFound = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <PageMetaData page={{ type: 'error404', template: 'error404' }} />
      <StaticErrorContent
        title={formatMessage({
          id: 'page.404.title',
          defaultMessage: '404 - SEITE WURDE NICHT GEFUNDEN',
        })}
        description={formatMessage({
          id: 'page.404.description',
          defaultMessage:
            'Leider gibt es die Seite, die du gesucht hast, auf unserer Website nicht.',
        })}
        info={formatMessage({
          id: 'page.404.info',
          defaultMessage:
            'Doch vielleicht haben wir trotzdem etwas für dich. Besuche unsere Startseite.',
        })}
        buttonLabel={formatMessage({
          id: 'page.404.button.label',
          defaultMessage: 'zur Startseite',
        })}
      />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default RootNotFound;
